import pin_icon from "../../utils/pin_icon.svg";

type SourceSelectProps = {
  sourceName: string;
  handleSourceSelect: any;
  page: number;
  setPageNumber: any;
  onPinSource?: (sourceName: string) => void;
};

const SourceSelectButton = ({
  sourceName,
  handleSourceSelect,
  setPageNumber,
  page,
  onPinSource,
}: SourceSelectProps) => {
  const handleClick = () => {
    handleSourceSelect(sourceName);
    setPageNumber(page);
  };

  return (
    <div className="relative group">
      <button
        type="button"
        value={sourceName}
        onClick={() => handleClick()}
        className="overflow-hidden min-w-[120px] max-w-[120px] border bg-gray-100 rounded-lg border-gray-400 text-bold focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-3 py-2 text-left me-2 mb-2"
      >
        {sourceName.slice(0, 50)}...
      </button>
      {onPinSource && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onPinSource(sourceName);
          }}
          className="absolute right-3 top-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 transform active:scale-75 hover:scale-110 transition-transform"
        >
          <img src={pin_icon} alt="Pin" className="w-4 h-4" />
        </button>
      )}
    </div>
  );
};

export default SourceSelectButton;
