import React, { useEffect, useRef } from "react";
import { useState, useCallback } from "react";
import useWindowWidth from "../../hooks/useWindowWidth";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import SpinnerLoader from "../SpinnerLoader";
import downloadIcon from "../../utils/dowload_icon.svg";

import type { PDFDocumentProxy } from "pdfjs-dist";

type PdfViewerProps = {
  pdfUrl: string;
  pageNumber: number;
};

const pdf_url =
  "https://fda-pdfs.s3.amazonaws.com/Guidance%20on%20Premarket%20Notification%20%5B510%28k%29%5D%20Submissions%20for%20Surgical%20Gowns%20and%20Surgical%20Drapes?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAU6GD33A7IWD7M6ZA%2F20240805%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240805T235918Z&X-Amz-Expires=1000&X-Amz-SignedHeaders=host&X-Amz-Signature=9925cc404d363c31cfe56544a7012078b5a44293696d1968f13058da671d10a1";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
};

const resizeObserverOptions = {};

type PDFFile = string | File | null;

function PDFViewer({ pdfUrl, pageNumber }: PdfViewerProps) {
  const [file, setFile] = useState<PDFFile>(pdfUrl);
  const [numPages, setNumPages] = useState<number>();
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();
  const [isPDFLoaded, setPdfLoaded] = useState<boolean>(false);
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false);
  const [maxWidth, setMaxWidth] = useState<number>(700);

  const windowWidth = useWindowWidth();

  const widthRef = useRef<HTMLDivElement>(null);

  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  function onFileChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { files } = event.target;

    const nextFile = files?.[0];

    if (nextFile) {
      setFile(nextFile);
    }
  }

  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: PDFDocumentProxy): void {
    setNumPages(nextNumPages);
    setLoadingComplete(true);
    console.log("PDF Loaded");
  }

  useEffect(() => {
    setPdfLoaded(false);
    setFile(pdfUrl);
  }, [pdfUrl]);

  useEffect(() => {
    if (loadingComplete) {
      setTimeout(() => {
        setPdfLoaded(true);
      }, 1000);
      setLoadingComplete(false);
    }
  }, [loadingComplete]);

  useEffect(() => {
    setMaxWidth(widthRef.current?.offsetWidth || 700);
  }, [windowWidth]);

  const handleDownload = useCallback(() => {
    fetch(pdfUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "FDA-Guidance-Document.pdf");
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
      });
  }, [pdfUrl]);

  return (
    <div
      className={`col-span-1 animate-slide-in-right hidden mdscreen:flex mdscreen:flex-col justify-between h-half-screen pt-5 border-black rounded-lg shadow-xl bg-white`}
      ref={widthRef}
    >
      <div
        className={`${
          isPDFLoaded ? "" : "hidden"
        } flex-grow flex flex-col gap-3 pt-5 overflow-y-scroll py-2`}
        ref={setContainerRef}
      >
        <button
          onClick={handleDownload}
          className="absolute top-5 right-5 z-10 hover:bg-gray-200 rounded-full p-1 transition-colors duration-200"
        >
          <img className="w-10 h-10" src={downloadIcon} alt="Download" />
        </button>
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
        >
          {numPages &&
            Array.from(new Array(numPages), (el, index) => (
              <React.Fragment key={`page_${index + 1}`}>
                <Page
                  pageNumber={index + 1}
                  inputRef={(ref) => {
                    if (ref && pageNumber === index + 1) {
                      ref.scrollIntoView();
                    }
                  }}
                  width={
                    containerWidth
                      ? Math.min(containerWidth, maxWidth)
                      : maxWidth
                  }
                />
                {index < numPages - 1 && (
                  <div
                    className="h-8 my-4 border-t border-gray-300"
                    aria-hidden="true"
                  />
                )}
              </React.Fragment>
            ))}
        </Document>
      </div>
      {!isPDFLoaded && <SpinnerLoader />}
    </div>
  );
}

export default PDFViewer;
