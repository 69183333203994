import React, { useState, useRef } from "react";

type PromptProps = {
  handleSendChat: (text: string) => void;
  isStreaming: boolean;
}

const Prompt = ({ handleSendChat, isStreaming }: PromptProps) => {
  const [text, setText] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Configure the maximum height (in pixels) below which the textarea will grow.
  const maxHeight = 150;

  // Adjusts the height of the textarea dynamically based on its content.
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      // Reset the height to "auto" so that scrollHeight is recalculated.
      textareaRef.current.style.height = "auto";
      const newHeight = Math.min(textareaRef.current.scrollHeight, maxHeight);
      textareaRef.current.style.height = `${newHeight}px`;
    }
  };

  const handleClick = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!text) return;

    // Capture the current text and clear the input immediately.
    const currentText = text;
    setText("");
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
    }

    try {
      await handleSendChat(currentText);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (isStreaming) return;

    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleClick(e as any);
    }
  };

  return (
    <div className="relative flex max-h-60 w-full grow flex-col overflow-hidden bg-background px-8 sm:rounded-md sm:px-12">
      <div className="flex w-full flex-col gap-1.5 rounded-[26px] p-1.5 transition-colors bg-[#f4f4f4]">
        <form className="flex items-end gap-1.5 md:gap-2" onSubmit={handleClick}>
          <div className="flex min-w-0 flex-1 flex-col pl-4">
            <textarea
              ref={textareaRef}
              value={text}
              onChange={(e) => {
                setText(e.target.value);
                adjustTextareaHeight();
              }}
              onKeyDown={handleKeyDown}
              rows={1}
              placeholder="Message ComplizenAI"
              className="flex min-h-[30px] w-full resize-none rounded-md bg-transparent px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
              style={{ overflowY: "auto", maxHeight: `${maxHeight}px` }}
            ></textarea>
          </div>
          <button
            type="submit"
            data-testid="fruitjuice-send-button"
            disabled={isStreaming}
            className="mb-1 me-1 flex h-8 w-8 items-center justify-center rounded-full bg-primary text-white transition-colors hover:opacity-70 focus-visible:outline-none focus-visible:outline-black disabled:bg-[#D7D7D7] disabled:text-[#f4f4f4] disabled:hover:opacity-100"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" className="icon-2xl">
              <path fill="currentColor" fillRule="evenodd" d="M15.192 8.906a1.143 1.143 0 0 1 1.616 0l5.143 5.143a1.143 1.143 0 0 1-1.616 1.616l-3.192-3.192v9.813a1.143 1.143 0 0 1-2.286 0v-9.813l-3.192 3.192a1.143 1.143 0 1 1-1.616-1.616z" clipRule="evenodd"></path>
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Prompt;
