import React, { useState, useRef, useEffect } from "react";
import Prompt from "../Prompt";
import ChatMessage from "../ChatMessage";
import TemplateQuestion from "../TemplateQuestion";
import TemplateChatValues from "./TemplateChatValues";
import SpinnerLoader from "../SpinnerLoader";
import { CHAT_ENDPOINT, ENDPOINT } from "../../utils/ENDPOINT";
import axios from "axios";
import complizen_icon from "../../utils/complizen_icon.png";

type ChatBlockProps = {
  conversationId: any;
  setConversationId: any;
  isFirst: boolean;
  setIsFirst: any;
  setChatCount: any;
  chatCount: number;
  setPdfUrl: any;
  setPageNumber: any;
  pdfUrl: string;
  setPinnedSources: any;
};

const LatestChatCount = 10;
const FINAL_DATA_DELIMITER = "###FINAL_DATA###";
const ERROR_DELIMITER = "###ERROR###";

async function customStreamHandler(payload: any) {
  const encoder = new TextEncoder();
  const decoder = new TextDecoder();

  const res = await fetch(CHAT_ENDPOINT + "/stream", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    credentials: "include",
  });

  if (!res.ok) {
    throw new Error(`HTTP error! status: ${res.status}`);
  }

  const reader = res.body?.getReader();
  if (!reader) {
    throw new Error("ReadableStream not supported");
  }

  return new ReadableStream({
    async start(controller) {
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        if (chunk.startsWith(FINAL_DATA_DELIMITER)) {
          controller.enqueue(encoder.encode(chunk));
          break;
        } else {
          controller.enqueue(encoder.encode(chunk));
        }
      }
      controller.close();
    },
  });
}

const ChatBlock = ({
  chatCount,
  conversationId,
  setConversationId,
  isFirst,
  setIsFirst,
  setChatCount,
  setPdfUrl,
  setPageNumber,
  pdfUrl,
  setPinnedSources,
}: ChatBlockProps) => {
  const [messages, setMessages] = useState<ChatMessageProps[]>([]);
  const [streamingMessage, setStreamingMessage] = useState<string>("");
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [lastScrollTop, setLastScrollTop] = useState<number>(0);
  const [blockScroll, setBlockScroll] = useState<boolean>(false);
  const [currentSouce, setCurrentSource] = useState<string>("");
  const [sources, setSources] = useState<any>([]);
  const [initialPdf, setInitialPdf] = useState<boolean>(false);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      // initial scroll
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });

      // one delayed scroll to catch any late-rendering content
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }, 300);
    }
  };

  const getConversationId = async (conversationId: string) => {
    try {
      let res = await axios.get(
        ENDPOINT + `/conversation/${conversationId}/messages`,
        { withCredentials: true }
      );

      let m: ChatMessageProps[] = [];

      res.data.forEach((element: any) => {
        let newProp: ChatMessageProps = {
          isAI: !element.is_from_user,
          text: element.content,
          message_id: element.id,
          source: JSON.parse(element.sources),
        };
        m.push(newProp);
      });

      setMessages([...m]);
      setIsFirst(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnSendChat = async (message: string) => {
    if (isStreaming) return;

    let newProp: ChatMessageProps = {
      isAI: false,
      text: message,
      message_id: -1,
    };

    const updatedArray = [...messages, newProp];
    setMessages(updatedArray);

    try {
      // TODO: below can be simplified to: let messagesToSend = messages.slice(-10);
      let messagesToSend = messages;
      if (messagesToSend.length > LatestChatCount) {
        messagesToSend = messagesToSend.slice(-LatestChatCount);
      }

      setIsStreaming(true);

      const res_profile = await axios.get(ENDPOINT + `/profile`, {
        withCredentials: true,
      });
      let profileData = null;
      if (res_profile.data !== null && res_profile.data !== undefined) {
        try {
          profileData = JSON.parse(JSON.stringify(res_profile.data));
        } catch (error) {
          console.error("Error parsing response data:", error);
        }
      }

      const payload = {
        text: message,
        is_first_msg: isFirst,
        conversation_id: conversationId,
        latest_messages: messagesToSend,
        user_profile: profileData.profile,
      };

      const stream = await customStreamHandler(payload);
      const reader = stream.getReader();
      const decoder = new TextDecoder();
      let accumulatedResponse = "";
      let final_source = null;
      let pdfUrlTemp = "";
      let pageNumberTemp = 0;

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);

        if (chunk.startsWith(FINAL_DATA_DELIMITER)) {
          const finalDataString = chunk.slice(FINAL_DATA_DELIMITER.length);
          try {
            const finalData = JSON.parse(finalDataString);
            if (finalData.sources !== null) {
              let first_source = finalData.sources[0]; // parsing only first source for now

              pdfUrlTemp = first_source.source_url;
              pageNumberTemp = parseInt(first_source.page);
              final_source = first_source.source;
            }
            setConversationId(finalData.conversation_id);
            let newProp1: ChatMessageProps = {
              isAI: true,
              text: accumulatedResponse,
              message_id: finalData.message_id,
              source: finalData.sources,
            };
            const updatedArray1 = [...updatedArray, newProp1];
            setMessages(updatedArray1);
            break;
          } catch (error) {
            console.error("Error parsing final data:", error);
          }
        } else if (chunk.startsWith(ERROR_DELIMITER)) {
          try {
            let error_msg: ChatMessageProps = {
              isAI: true,
              text: "Something went wrong. Please start a new chat and try again!",
              message_id: -2,
              source: null,
            };
            const updatedArray1 = [...updatedArray, error_msg];
            setMessages(updatedArray1);
            break;
          } catch (error) {
            console.error("Error parsing error data:", error);
          }
        } else {
          accumulatedResponse += chunk;
          setStreamingMessage(accumulatedResponse);
        }
      }

      setPdfUrl(pdfUrlTemp);
      setPageNumber(pageNumberTemp);

      setStreamingMessage("");
      setIsStreaming(false);
      setBlockScroll(false);
      if (isFirst) {
        setChatCount(chatCount + 1);
        setIsFirst(false);
      }
    } catch (error: any) {
      setIsStreaming(false);
      console.log(error.message);
      window.location.reload(); // TODO: what's the purpose of this? wouldn't below be better?
      // setStreamingMessage("An error occurred while fetching the response. Please try again.");
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, streamingMessage, conversationId]);

  useEffect(() => {
    if (!initialPdf) {
      setInitialPdf(true);

      for (let i = messages.length - 1; i >= 0; i--) {
        let m = messages[i];
        if (m.source && m.source.length > 0) {
          setCurrentSource(m.source[0].source);
          setPageNumber(parseInt(m.source[0].page));
          return;
        }
      }
    }
  }, [messages]);

  useEffect(() => {
    setPdfUrl("");
    setCurrentSource("");
    setSources([]);
    setInitialPdf(false);
    if (conversationId > 0) {
      getConversationId(conversationId);
    } else {
      setConversationId(0);
      setIsFirst(true);
      setMessages([]);
    }
  }, [conversationId]);

  useEffect(() => {
    const getPdfUrl = async () => {
      let res = await axios.post(
        ENDPOINT + "/s3-url",
        { source: currentSouce },
        { withCredentials: true }
      );

      setPdfUrl(res.data.url);
    };
    if (currentSouce && currentSouce !== "") {
      getPdfUrl();
    } else {
      setPdfUrl("");
    }
  }, [currentSouce]);
  let a =
    "w-[400px] laptopS:w-[500px] laptopXL:w-[700px] animate-slide-out-left";

  return (
    <div
      className={`${pdfUrl ? "col-span-2 mdscreen:col-span-1" : "col-span-2"}`}
    >
      <div
        className={`${
          pdfUrl ? "col-span-1" : "col-span-2"
        } max-w-[1000px] flex flex-col mx-auto justify-between h-half-screen pt-5 border-black rounded-lg shadow-xl bg-white`}
      >
        <div className="flex-grow flex flex-col gap-3 pt-5 overflow-y-auto">
          {messages.length === 0 && conversationId === 0 && (
            <div className="h-100p flex flex-col">
              <div className="mx-auto my-auto">
                <img
                  src={complizen_icon}
                  className="w-12 h-12"
                  alt="Complizen Icon"
                />
              </div>
              <div className="mt-auto flex flex-col gap-4">
                <div className="mt-auto mx-20 md:max-w-5xl pb-2 grid grid-cols-2 gap-2 items-center">
                  {TemplateChatValues.map((value, index) => (
                    <TemplateQuestion
                      key={index}
                      handleChat={handleOnSendChat}
                      text={value.text}
                      value={value.value}
                      explanation={value.explanation}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
          {messages.map((m) => {
            return (
              <ChatMessage
                key={m.message_id}
                isAI={m.isAI}
                text={m.text}
                message_id={m.message_id}
                source={m.source}
                setCurrentSource={setCurrentSource}
                setPageNumber={setPageNumber}
                setPinnedSources={setPinnedSources}
              />
            );
          })}
          {isStreaming && !streamingMessage && (
            <div className="mb-auto">
              <SpinnerLoader />
            </div>
          )}
          {streamingMessage && (
            <ChatMessage
              isAI={true}
              text={streamingMessage}
              message_id={1}
              isStreaming
              setPinnedSources={setPinnedSources}
            /> // TODO: will update backend to return message_id at the end
          )}
          <div ref={scrollRef}></div>
        </div>
        <div className="mt-auto p-4">
          <Prompt handleSendChat={handleOnSendChat} isStreaming={isStreaming} />
        </div>
      </div>
    </div>
  );
};

export default ChatBlock;
