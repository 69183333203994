import React, { useState } from "react";
import SourceGroup from "../SourceGroup";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import copyIcon from "../../utils/copy_icon.svg";
import copyIconBold from "../../utils/copy_icon_bold.svg";
import dislikeIcon from "../../utils/dislike.svg";
import dislikeIconBold from "../../utils/dislike_bold.svg";
import likeIcon from "../../utils/like.svg";
import likeIconBold from "../../utils/like_bold.svg";
import complizen_icon from "../../utils/complizen_icon.png";
import "../../markdown-styles.css";
import axios from "axios";
import { ENDPOINT } from "../../utils/ENDPOINT";

type ChatResponseProps = {
  onFeedBackClick: any;
  onLikeClick: any;
  text: string;
  isStreaming?: boolean;
  sources?: any;
  setCurrentSource?: any;
  setPageNumber?: any;
  setPinnedSources: React.Dispatch<React.SetStateAction<any[]>>;
};

const ChatResponse = (props: ChatResponseProps) => {
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleDislikeClick = () => {
    if (isDisliked) {
      return;
    }
    setIsDisliked(!isDisliked);
    setIsLiked(false); // Ensure like is not active when dislike is clicked
    props.onFeedBackClick();
  };

  const handleLikeClick = () => {
    if (isLiked) {
      return;
    }
    setIsLiked(!isLiked);
    setIsDisliked(false); // Ensure dislike is not active when like is clicked
    props.onLikeClick();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(props.text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handlePinSource = async (sourceName: string) => {
    try {
      await axios.post(
        `${ENDPOINT}/pinned-sources`,
        { source: sourceName },
        { withCredentials: true }
      );
      const res = await axios.get(`${ENDPOINT}/pinned-sources`, {
        withCredentials: true,
      });
      props.setPinnedSources(res.data.pinned_sources);
    } catch (error) {
      console.error("Error pinning source:", error);
    }
  };

  return (
    <div>
      <div className="mx-10 laptopM:mx-8 flex flex-1 gap-3 text-base md:max-w-3xl text-left">
        <div className="flex-shrink-0 flex flex-row relative items-start">
          <div className="flex h-7 w-7 items-center justify-center overflow-hidden rounded-full">
            <img src={complizen_icon} alt="Complizen" />
          </div>
        </div>
        <div className="flex w-full min-w-0 flex-col agent-turn gap-2">
          <div className="min-h-[20px] flex flex-col items-start text-left w-full gap-1 break-words overflow-x-auto">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                a: ({ node, children, ...props }) => (
                  <a target="_blank" rel="noopener noreferrer" {...props}>
                    {children}
                  </a>
                ),
              }}
              className="markdown-content"
            >
              {props.text}
            </ReactMarkdown>
          </div>
          {!props.isStreaming && (
            <div className="mt-1 flex gap-1">
              <button
                onClick={() => handleCopy()}
                className="rounded-lg text-token-text-secondary hover:bg-[#f4f4f4]"
                title="Copy"
              >
                <img
                  className="w-7"
                  src={isCopied ? copyIconBold : copyIcon}
                  alt="Copy"
                />
              </button>
              <button
                onClick={handleDislikeClick}
                className="rounded-lg text-token-text-secondary hover:bg-[#f4f4f4] p-0"
                title="Dislike"
              >
                <img
                  className="w-7"
                  src={isDisliked ? dislikeIconBold : dislikeIcon}
                  alt="Dislike"
                />
              </button>
              <button
                onClick={handleLikeClick}
                className="rounded-lg text-token-text-secondary hover:bg-[#f4f4f4] p-0"
                title="Like"
              >
                <img
                  className="w-7"
                  src={isLiked ? likeIconBold : likeIcon}
                  alt="Like"
                />
              </button>
            </div>
          )}
        </div>
      </div>
      {props.sources && (
        <SourceGroup
          sources={props.sources}
          setCurrentSource={props.setCurrentSource}
          setPageNumber={props.setPageNumber}
          onPinSource={handlePinSource}
        />
      )}
    </div>
  );
};

export default ChatResponse;
