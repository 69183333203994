import React, { useEffect, useState, useRef } from "react";
import SidebarItem from "../SidebarItem";
import ConfirmationModal from "../ConfirmationModal";
import DropdownMenu from "../DropdownMenu";
import logo from "../../utils/complizen_logo.png";
import options_icon from "../../utils/options_icon.svg";
import rename_icon from "../../utils/rename_icon.svg";
import delete_icon from "../../utils/delete_icon.svg";
import pin_icon from "../../utils/pin_icon.svg";
import unpin_icon from "../../utils/unpin_icon.svg";
import clipboard_icon from "../../utils/clipboard_icon.svg";
import axios from "axios";
import { ENDPOINT } from "../../utils/ENDPOINT";
import ReactDOM from "react-dom";

type ChatSidebarProps = {
  setConversationId: any;
  chatCount: number;
  setIsFirst: any;
  conversationId: number;
  hamburger?: boolean;
  handleCloseClick?: any;
  setPdfUrl?: any;
  pinnedSources: any[];
  setPinnedSources: any;
  mode: "home" | "archive";
};

type Conversation = {
  id: number;
  user_id: number;
  title: string;
  is_pinned: boolean;
  created_at: string;
  updated_at: string;
};

const ChatSidebar = ({
  chatCount,
  setConversationId,
  conversationId,
  setIsFirst,
  hamburger = false,
  handleCloseClick,
  setPdfUrl,
  pinnedSources,
  setPinnedSources,
  mode,
}: ChatSidebarProps) => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [active, setActive] = useState(0);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [renamingConversationId, setRenamingConversationId] = useState<
    number | null
  >(null);
  const [newTitle, setNewTitle] = useState<string>("");
  const [dropdownOpenId, setDropdownOpenId] = useState<number | null>(null);
  const [dropdownPosition, setDropdownPosition] = useState<{
    [key: number]: "above" | "below";
  }>({});
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    id: number;
    title: string;
  } | null>(null);

  // Refs to hold the 3-dot icons for positioning
  const optionsRefs = useRef<{ [key: number]: HTMLImageElement | null }>({});
  // Ref to the scrollable container
  const scrollableRef = useRef<HTMLDivElement | null>(null);

  const fetchConversations = async () => {
    try {
      const res = await axios.get(`${ENDPOINT}/user-conversations`, {
        withCredentials: true,
      });
      setConversations(res.data);
    } catch (error) {
      console.log("Error fetching conversations: ", error);
    }
  };

  useEffect(() => {
    fetchConversations();
  }, [chatCount]);

  useEffect(() => {
    const fetchPinnedSources = async () => {
      try {
        const res = await axios.get(`${ENDPOINT}/pinned-sources`, {
          withCredentials: true,
        });
        setPinnedSources(res.data.pinned_sources);
      } catch (error) {
        console.log("Error fetching pinned sources: ", error);
      }
    };
    fetchPinnedSources();
  }, []);

  const getAndsetPdfUrl = async (source: string) => {
    const res = await axios.post(
      `${ENDPOINT}/s3-url`,
      {
        source: source,
      },
      { withCredentials: true }
    );
    setPdfUrl(res.data.url);
  };

  const setNewChatActive = (i: number) => {
    setActive(i);
    if (setPdfUrl) {
      setPdfUrl("");
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await axios.delete(`${ENDPOINT}/conversation/${id}`, {
        withCredentials: true,
      });
      // Refresh the conversations list after deletion
      fetchConversations();
      setDeleteConfirmation(null);
    } catch (error) {
      console.log("Error deleting conversation: ", error);
    }
  };

  const handleRename = (id: number, currentTitle: string) => {
    setRenamingConversationId(id);
    setNewTitle(currentTitle);
    setDropdownOpenId(null);
  };

  const submitRename = async (id: number) => {
    if (newTitle.trim() === "") {
      alert("Title cannot be empty.");
      return;
    }
    try {
      await axios.put(
        `${ENDPOINT}/conversation/${id}/title`,
        { new_title: newTitle },
        { withCredentials: true }
      );
      // Refresh the conversations list after renaming
      fetchConversations();
      setRenamingConversationId(null);
      setNewTitle("");
    } catch (error) {
      console.log("Error renaming conversation: ", error);
    }
  };

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    id: number
  ) => {
    if (e.key === "Enter") {
      submitRename(id);
    }
  };

  const toggleDropdown = (id: number) => {
    if (dropdownOpenId === id) {
      setDropdownOpenId(null);
      return;
    }

    // Get the position of the 3-dot icon relative to the scrollable container
    const iconElement = optionsRefs.current[id];
    const scrollableElement = scrollableRef.current;
    if (iconElement && scrollableElement) {
      const iconRect = iconElement.getBoundingClientRect();
      const scrollableRect = scrollableElement.getBoundingClientRect();
      const dropdownHeight = 280; // estimated height of dropdown
      const spaceBelow = scrollableRect.bottom - iconRect.bottom;
      const spaceAbove = iconRect.top - scrollableRect.top;

      if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
        setDropdownPosition((prev) => ({ ...prev, [id]: "above" }));
      } else {
        setDropdownPosition((prev) => ({ ...prev, [id]: "below" }));
      }
    }

    setDropdownOpenId(id);
  };

  // Handler to toggle pin/unpin
  const handleTogglePin = async (id: number, currentPinned: boolean) => {
    try {
      await axios.put(
        `${ENDPOINT}/conversation/${id}/pin`,
        { is_pinned: !currentPinned },
        { withCredentials: true }
      );
      // Refresh the conversations list after toggling pin
      fetchConversations();
    } catch (error) {
      console.log("Error toggling pin: ", error);
    }
  };

  const handleDeleteSource = async (source: string) => {
    try {
      await axios.delete(`${ENDPOINT}/pinned-sources`, {
        data: { source: source },
        withCredentials: true,
      });
      const res = await axios.get(`${ENDPOINT}/pinned-sources`, {
        withCredentials: true,
      });
      setPinnedSources(res.data.pinned_sources);
      setDeleteConfirmation(null);
    } catch (error) {
      console.log("Error deleting source: ", error);
    }
  };

  // Separate conversations into pinned and recent
  const pinnedConversations = conversations.filter((convo) => convo.is_pinned);
  const recentConversations = conversations.filter((convo) => !convo.is_pinned);

  // Render the modal using a portal
  const renderModal = () => {
    const modalRoot = document.getElementById("modal-root");
    if (!modalRoot || !deleteConfirmation) return null;

    return ReactDOM.createPortal(
      <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50">
        <ConfirmationModal
          title={
            typeof deleteConfirmation.id === "number"
              ? "Delete Chat?"
              : "Delete Document?"
          }
          message={
            <span>
              Are you sure you want to delete{" "}
              <span className="font-bold">"{deleteConfirmation.title}"</span>?
              <br />
              <br />
              {typeof deleteConfirmation.id === "number"
                ? "This action cannot be undone."
                : "This will only remove it from your pinned documents."}
            </span>
          }
          onConfirm={() =>
            typeof deleteConfirmation.id === "number"
              ? handleDelete(deleteConfirmation.id)
              : handleDeleteSource(deleteConfirmation.id)
          }
          onCancel={() => setDeleteConfirmation(null)}
        />
      </div>,
      modalRoot
    );
  };

  return (
    <div
      className="flex flex-col overflow-hidden w-60 h-screen bg-white shadow-lg px-5 py-5 space-y-4"
      ref={scrollableRef}
    >
      {/* below was from old hamburger implementation, comment out for now */}
      {/* {!hamburger ? (
        <div className="row-span-2 border-gray-300">
          <img src={logo} alt="logo" />
        </div>
      ) : (
        <div className="ml-auto mr-1" onClick={() => handleCloseClick()}>
          <button className="text-gray-500 hover:text-gray-700 focus:outline-none">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      )} */}

      <div className="grow shrink basis-0 py-4 overflow-y-auto overflow-x-hidden">
        <ul className="text-sm">
          {/* Show "Start New Chat" only in home mode */}
          {mode === "home" && (
            <>
              <SidebarItem
                name="Start New Chat"
                to="/"
                active={0 === conversationId}
                conversation_id={0}
                setConversationId={setConversationId}
                index={0}
                setActive={setNewChatActive}
              />
              <li>
                <hr className="border-t border-gray-300 mt-4 mb-6" />
              </li>
            </>
          )}

          {/* Show Pinned Conversations only in chat mode */}
          {mode === "archive" && pinnedConversations.length > 0 && (
            <>
              <li className="mt-2 mb-1 text-xs text-gray-500">Pinned Chats</li>
              {pinnedConversations.map((conversation, index) => (
                <div
                  key={conversation.id}
                  className="relative group flex items-center hover:bg-secondary rounded-lg flex-row transition-colors duration-300 w-full"
                  onClick={() => {
                    setConversationId(conversation.id);
                    setIsFirst(false);
                  }}
                >
                  {renamingConversationId === conversation.id ? (
                    <input
                      type="text"
                      value={newTitle}
                      onChange={(e) => setNewTitle(e.target.value)}
                      onKeyDown={(e) => handleKeyPress(e, conversation.id)}
                      onBlur={() => setRenamingConversationId(null)}
                      autoFocus
                      className="flex-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-secondary"
                    />
                  ) : (
                    <SidebarItem
                      name={conversation.title}
                      conversation_id={conversation.id}
                      setConversationId={setConversationId}
                      to={`/conversation/${conversation.id}`}
                      active={conversationId === conversation.id}
                      index={index + 1}
                      setActive={setActive}
                    />
                  )}
                  {/* Options Button */}
                  <div className="absolute right-2 top-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center">
                    <img
                      src={options_icon}
                      alt="Options"
                      className="w-4 h-4 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(conversation.id);
                      }}
                      title="Options"
                      ref={(el) => (optionsRefs.current[conversation.id] = el)}
                    />
                    {/* Dropdown Pop-up */}
                    {dropdownOpenId === conversation.id && (
                      <DropdownMenu
                        isOpen={true}
                        onClose={() => setDropdownOpenId(null)}
                        position={dropdownPosition[conversation.id] || "below"}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRename(conversation.id, conversation.title);
                          }}
                          className="flex items-center w-full text-left px-2 py-1 hover:bg-secondary rounded-t-md"
                        >
                          <img
                            src={rename_icon}
                            alt="Rename"
                            className="w-4 h-4 mr-2"
                          />
                          Rename
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTogglePin(
                              conversation.id,
                              conversation.is_pinned
                            );
                            setDropdownOpenId(null);
                          }}
                          className="flex items-center w-full text-left px-2 py-1 hover:bg-secondary"
                        >
                          <img
                            src={conversation.is_pinned ? unpin_icon : pin_icon}
                            alt={conversation.is_pinned ? "Unpin" : "Pin"}
                            className="w-4 h-4 mr-2"
                          />
                          {conversation.is_pinned ? "Unpin" : "Pin"}
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteConfirmation({
                              id: conversation.id,
                              title: conversation.title,
                            });
                            setDropdownOpenId(null);
                          }}
                          className="flex items-center w-full text-left px-2 py-1 text-red-500 hover:bg-secondary rounded-b-md"
                        >
                          <img
                            src={delete_icon}
                            alt="Delete"
                            className="w-4 h-4 mr-2"
                          />
                          Delete
                        </button>
                      </DropdownMenu>
                    )}
                  </div>
                </div>
              ))}
              <li>
                <hr className="border-t border-gray-300 mt-4 mb-4" />
              </li>
            </>
          )}

          {/* Show Pinned Sources only in chat mode */}
          {mode === "archive" && pinnedSources.length > 0 && (
            <>
              <li className="mt-2 mb-1 text-xs text-gray-500">Docs</li>
              {pinnedSources.map((source, index) => (
                <div
                  key={index}
                  className="relative group flex items-center hover:bg-secondary rounded-lg flex-row transition-colors duration-300 w-full"
                  onClick={() => {
                    getAndsetPdfUrl(source);
                  }}
                >
                  <SidebarItem
                    name={source}
                    conversation_id={conversationId}
                    setConversationId={setConversationId}
                    to=""
                    active={false}
                    index={index}
                    setActive={() => {}}
                  />
                  {/* Tooltip for long document names - positioned to the right and with high z-index */}
                  <span className="fixed left-60 top-50 px-2 py-1 bg-gray-800 text-white text-sm rounded opacity-0 group-hover:animate-tooltip-fade whitespace-nowrap z-50">
                    {source}
                  </span>
                  {/* Options Button */}
                  <div className="absolute right-2 top-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center">
                    <img
                      src={options_icon}
                      alt="Options"
                      className="w-4 h-4 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(source);
                      }}
                      title="Options"
                      ref={(el) => (optionsRefs.current[source] = el)}
                    />
                    {dropdownOpenId === source && (
                      <DropdownMenu
                        isOpen={true}
                        onClose={() => setDropdownOpenId(null)}
                        position={dropdownPosition[source] || "below"}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(source);
                            setDropdownOpenId(null);
                            // Optional: Add a toast notification or some feedback
                          }}
                          className="flex items-center w-full text-left px-2 py-1 hover:bg-secondary rounded-t-md"
                        >
                          <img
                            src={clipboard_icon}
                            alt="Copy"
                            className="w-4 h-4 mr-2"
                          />
                          Copy
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteConfirmation({
                              id: source,
                              title: source,
                            });
                            setDropdownOpenId(null);
                          }}
                          className="flex items-center w-full text-left px-2 py-1 text-red-500 hover:bg-secondary rounded-b-md"
                        >
                          <img
                            src={delete_icon}
                            alt="Delete"
                            className="w-4 h-4 mr-2"
                          />
                          Delete
                        </button>
                      </DropdownMenu>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}

          {/* Show Recent Conversations only in home mode */}
          {mode === "home" && (
            <>
              <li className="mt-2 mb-1 text-xs text-gray-500">Recents</li>
              {recentConversations.map((conversation, index) => (
                <div
                  key={conversation.id}
                  className="relative group flex items-center hover:bg-secondary rounded-lg flex-row transition-colors duration-300 w-full"
                  onClick={() => {
                    setConversationId(conversation.id);
                    setIsFirst(false);
                  }}
                >
                  {renamingConversationId === conversation.id ? (
                    <input
                      type="text"
                      value={newTitle}
                      onChange={(e) => setNewTitle(e.target.value)}
                      onKeyDown={(e) => handleKeyPress(e, conversation.id)}
                      onBlur={() => setRenamingConversationId(null)}
                      autoFocus
                      className="flex-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-secondary"
                    />
                  ) : (
                    <SidebarItem
                      name={conversation.title}
                      conversation_id={conversation.id}
                      setConversationId={setConversationId}
                      to={`/conversation/${conversation.id}`}
                      active={conversationId === conversation.id}
                      index={index + 1 + pinnedConversations.length}
                      setActive={setActive}
                    />
                  )}
                  {/* Options Button */}
                  <div className="absolute right-2 top-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center">
                    <img
                      src={options_icon}
                      alt="Options"
                      className="w-4 h-4 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(conversation.id);
                      }}
                      title="Options"
                      ref={(el) => (optionsRefs.current[conversation.id] = el)}
                    />
                    {/* Dropdown Pop-up */}
                    {dropdownOpenId === conversation.id && (
                      <DropdownMenu
                        isOpen={true}
                        onClose={() => setDropdownOpenId(null)}
                        position={dropdownPosition[conversation.id] || "below"}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRename(conversation.id, conversation.title);
                          }}
                          className="flex items-center w-full text-left px-2 py-1 hover:bg-secondary rounded-t-md"
                        >
                          <img
                            src={rename_icon}
                            alt="Rename"
                            className="w-4 h-4 mr-2"
                          />
                          Rename
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTogglePin(
                              conversation.id,
                              conversation.is_pinned
                            );
                            setDropdownOpenId(null);
                          }}
                          className="flex items-center w-full text-left px-2 py-1 hover:bg-secondary"
                        >
                          <img
                            src={conversation.is_pinned ? unpin_icon : pin_icon}
                            alt={conversation.is_pinned ? "Unpin" : "Pin"}
                            className="w-4 h-4 mr-2"
                          />
                          {conversation.is_pinned ? "Unpin" : "Pin"}
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteConfirmation({
                              id: conversation.id,
                              title: conversation.title,
                            });
                            setDropdownOpenId(null);
                          }}
                          className="flex items-center w-full text-left px-2 py-1 text-red-500 hover:bg-secondary rounded-b-md"
                        >
                          <img
                            src={delete_icon}
                            alt="Delete"
                            className="w-4 h-4 mr-2"
                          />
                          Delete
                        </button>
                      </DropdownMenu>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </ul>
      </div>

      {renderModal()}
    </div>
  );
};

export default ChatSidebar;
