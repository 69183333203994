import React from "react";
import hanburgerLogo from "../../utils/menu.png";
import { useState } from "react";

import ChatSidebar from "../ChatSideBar";

type HamburgerMenuProps = {
  chatCount: number;
  setConversationId: any;
  conversationId: number;
  setIsFirst: any;
  pinnedSources: any[];
  setPinnedSources: any;
};

const HamburgerMenu = ({
  chatCount,
  setConversationId,
  conversationId,
  setIsFirst,
  pinnedSources,
  setPinnedSources,
}: HamburgerMenuProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className="mt-1 block tablet:hidden cursor-pointer">
      <div onClick={() => setVisible(!visible)} className="w-5 ml-5 mt-5">
        <img src={hanburgerLogo} alt="Menu" />
      </div>
      {visible && (
        <div
          className="w-screen h-full top-0 left-0 z-10 bg-black bg-opacity-25 absolute"
          onClick={() => setVisible(false)}
        >
          <div className="" onClick={(e) => e.stopPropagation()}>
            <ChatSidebar
              chatCount={chatCount}
              setConversationId={setConversationId}
              conversationId={conversationId}
              setIsFirst={setIsFirst}
              hamburger
              handleCloseClick={() => setVisible(false)}
              pinnedSources={pinnedSources}
              setPinnedSources={setPinnedSources}
              mode="archive"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
