import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ChatSidebar from "../../components/ChatSideBar";
import FixedSideBar from "../../components/FixedSideBar/FixedSideBar";
import HamburgerMenu from "../../components/HamburgerMenu";
import ChatBlock from "../../components/ChatBlock";
import LogInModal from "../../components/LogInModal";
import PDFViewer from "../../components/PDFViewer";
import { ENDPOINT } from "../../utils/ENDPOINT";

const ChatPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [conversationId, setConversationId] = useState(() => {
    const saved = localStorage.getItem("conversationId");
    return saved ? parseInt(saved, 10) : 0;
  });
  const [isFirst, setIsFirst] = useState(true);
  const [chatCount, setChatCount] = useState(0);
  const [pdfUrl, setPdfUrl] = useState(() => {
    const saved = localStorage.getItem("pdfUrl");
    return saved && saved !== "null" && saved !== "undefined" ? saved : "";
  });
  const [pageNumber, setPageNumber] = useState(() => {
    const saved = localStorage.getItem("pageNumber");
    return saved ? parseInt(saved, 10) : 1;
  });
  const [activeSidebar, setActiveSidebar] = useState(() => {
    const saved = localStorage.getItem("activeSidebar");
    return saved || null;
  });
  const [pinnedSources, setPinnedSources] = useState<any[]>([]);
  const navigate = useNavigate();

  const checkAuth = async () => {
    try {
      const res = await axios.post(
        ENDPOINT + "/auth",
        {},
        { withCredentials: true }
      );
      if (res.data.detail === "invalid token") {
        setShowModal(true);
      } else {
        setShowModal(false);
      }
      const profileRequest = await axios.get(ENDPOINT + "/profile", {
        withCredentials: true,
      });
      const profileData = profileRequest.data;
      if (!profileData) {
        navigate("/profile");
      }
    } catch (error) {
      setShowModal(true);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  const handleFixedSideBarClick = (iconId: string) => {
    // Toggle the dynamic sidebar – close if the same icon is clicked again.
    setActiveSidebar((prev) => (prev === iconId ? null : iconId));
  };

  const saveStateToLocalStorage = () => {
    localStorage.setItem("activeSidebar", activeSidebar || "");
    if (pdfUrl) {
      localStorage.setItem("pdfUrl", pdfUrl);
    }
    localStorage.setItem("pageNumber", pageNumber.toString());
    localStorage.setItem("conversationId", conversationId.toString());
  };

  useEffect(() => {
    saveStateToLocalStorage();
  }, [activeSidebar, pdfUrl, pageNumber, conversationId]);

  return (
    <div className="flex flex-row bg-[#F6F5FA]">
      {showModal && (
        <LogInModal setShowModal={setShowModal} setChatCount={setChatCount} />
      )}
      {/* Fixed sidebar on the far left */}
      <FixedSideBar onIconClick={handleFixedSideBarClick} />

      {/* Dynamic (slide-in) sidebar only shows on tablet+ */}
      <div
        className={`fixed top-0 left-12 h-screen w-60 bg-white shadow-lg transition-transform duration-300 ease-in-out z-10 hidden tablet:block ${
          activeSidebar ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        {(activeSidebar === "archive" || activeSidebar === "home") && (
          <ChatSidebar
            chatCount={chatCount}
            setConversationId={setConversationId}
            setIsFirst={setIsFirst}
            conversationId={conversationId}
            setPdfUrl={setPdfUrl}
            pinnedSources={pinnedSources}
            setPinnedSources={setPinnedSources}
            mode={activeSidebar as "home" | "archive"}
          />
        )}
      </div>

      {/* Mobile hamburger menu */}
      <div className="tablet:hidden">
        <HamburgerMenu
          chatCount={chatCount}
          setConversationId={setConversationId}
          conversationId={conversationId}
          setIsFirst={setIsFirst}
          pinnedSources={pinnedSources}
          setPinnedSources={setPinnedSources}
        />
      </div>

      {/* Main content area; adjust left margin based on whether the dynamic (ChatsSideBar) is open */}
      <div
        className={`flex-1 transition-all duration-300 ease-in-out ${
          activeSidebar ? "ml-72" : "ml-12"
        } px-5 py-15 h-screen overflow-hidden`}
      >
        <div className="grid grid-cols-2 gap-5 py-3">
          <ChatBlock
            conversationId={conversationId}
            setConversationId={setConversationId}
            isFirst={isFirst}
            setIsFirst={setIsFirst}
            setChatCount={setChatCount}
            chatCount={chatCount}
            setPdfUrl={setPdfUrl}
            setPageNumber={setPageNumber}
            pdfUrl={pdfUrl}
            setPinnedSources={setPinnedSources}
          />
          {pdfUrl && <PDFViewer pdfUrl={pdfUrl} pageNumber={pageNumber} />}
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
