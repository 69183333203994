import { useState } from "react";
import axios from "axios";
import UserMessage from "../UserMessage";
import ChatResponse from "../ChatResponse";
import FeedbackModal from "../FeedbackModal";
import { ENDPOINT } from "../../utils/ENDPOINT";

const ChatMessage = (props: ChatMessageProps) => {
  const [feedBackModal, setFeedBackModal] = useState(false);

  const onFeedBackClick = () => {
    setFeedBackModal(true);
  };

  const onLikeClick = async () => {
    const res = await axios.post(
      ENDPOINT + "/feedback",
      {
        message_id: props.message_id,
        is_thumbs_up: true,
        feedback: null,
      },
      {
        withCredentials: true,
      }
    );
  };

  return (
    <div>
      {feedBackModal && (
        <FeedbackModal
          setShowModal={setFeedBackModal}
          message_id={props.message_id}
        />
      )}
      {props.isAI ? (
        <ChatResponse
          onFeedBackClick={onFeedBackClick}
          onLikeClick={onLikeClick}
          text={props.text}
          isStreaming={props.isStreaming}
          sources={props.source}
          setCurrentSource={props.setCurrentSource}
          setPageNumber={props.setPageNumber}
          setPinnedSources={props.setPinnedSources}
        />
      ) : (
        <UserMessage text={props.text} />
      )}
    </div>
  );
};

export default ChatMessage;
