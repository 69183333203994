import React, { useState } from "react";
import { Link } from "react-router-dom";
import FeedbackModal from "../FeedbackModal";
import complizenLogo from "../../utils/complizen_icon.png";
import folderIcon from "../../utils/folder_icon.svg";
import homeIcon from "../../utils/home_icon.svg";
import feedback_icon from "../../utils/feedback.svg";
import profile_icon from "../../utils/user-profile.png";
import logout_icon from "../../utils/logout.svg";
import { ENDPOINT } from "../../utils/ENDPOINT";
import axios from "axios";

export interface FixedSideBarProps {
  onIconClick: (iconId: string) => void;
}

const FixedSideBar: React.FC<FixedSideBarProps> = ({ onIconClick }) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const handleLogOut = async () => {
    try {
      const res = await axios.post(
        `${ENDPOINT}/log-out`,
        {},
        { withCredentials: true }
      );
      console.log("Log out response: ", res.data);

      // Clear all localStorage items
      localStorage.clear();

      window.location.reload();
    } catch (error) {
      console.log("Error logging out: ", error);
    }
  };

  return (
    <div className="fixed top-0 left-0 h-screen w-12 bg-white shadow flex flex-col items-center py-4 z-20">
      {/* Top section */}
      <div className="flex flex-col items-center space-y-2">
        <div className="row-span-1 border-gray-300 px-2 mb-8">
          <img
            src={complizenLogo}
            alt="logo"
            className="w-8 h-8 object-contain"
          />
        </div>

        <button
          onClick={() => onIconClick("home")}
          className="p-2 rounded hover:bg-gray-100 focus:outline-none group relative"
        >
          <img src={homeIcon} alt="Home" className="h-6 w-6 text-gray-700" />
          <span className="absolute left-full ml-2 px-2 py-1 bg-gray-800 text-white text-sm rounded opacity-0 group-hover:animate-tooltip-fade whitespace-nowrap">
            Home
          </span>
        </button>

        <button
          onClick={() => onIconClick("archive")}
          className="p-2 rounded hover:bg-gray-100 focus:outline-none group relative"
        >
          <img src={folderIcon} alt="Chat" className="h-6 w-6 text-gray-700" />
          <span className="absolute left-full ml-2 px-2 py-1 bg-gray-800 text-white text-sm rounded opacity-0 group-hover:animate-tooltip-fade whitespace-nowrap">
            Chats
          </span>
        </button>
      </div>

      {/* Bottom section */}
      <div className="mt-auto flex flex-col items-center space-y-2">
        <button
          onClick={() => setShowFeedbackModal(true)}
          className="p-2 rounded hover:bg-gray-100 focus:outline-none group relative"
        >
          <img
            src={feedback_icon}
            alt="feedback"
            className="h-6 w-6 text-gray-700"
          />
          <span className="absolute left-full ml-2 px-2 py-1 bg-gray-800 text-white text-sm rounded opacity-0 group-hover:animate-tooltip-fade whitespace-nowrap">
            Feedback
          </span>
        </button>

        <Link
          to="/profile"
          className="p-2 rounded hover:bg-gray-100 focus:outline-none group relative"
        >
          <img src={profile_icon} alt="icon" className="w-5 h-5" />
          <span className="absolute left-full ml-2 px-2 py-1 bg-gray-800 text-white text-sm rounded opacity-0 group-hover:animate-tooltip-fade whitespace-nowrap">
            Profile
          </span>
        </Link>

        <button
          onClick={() => handleLogOut()}
          className="p-2 rounded hover:bg-gray-100 focus:outline-none group relative"
        >
          <img
            src={logout_icon}
            alt="logout"
            className="h-6 w-6 text-gray-700"
          />
          <span className="absolute left-full ml-2 px-2 py-1 bg-gray-800 text-white text-sm rounded opacity-0 group-hover:animate-tooltip-fade whitespace-nowrap">
            Logout
          </span>
        </button>
      </div>

      {/* Feedback Modal */}
      {showFeedbackModal && (
        <FeedbackModal setShowModal={setShowFeedbackModal} message_id={-5} />
      )}
    </div>
  );
};

export default FixedSideBar;
